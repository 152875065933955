import React from "react";
import Loader from "../Loader/Loader";
import Error from "../Error/Error";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

function PageLayout({
  children,
  summary,
  title,
  isLoading,
  isError,
  errorText,
  backBtn,
  backRoute,
  className,
  mt,
  backBtnClassname,   
  containerBackground='bg-white',
  withSection=false,
  section
}) {
  
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error err={errorText || null} />;
  }

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (backRoute) {
      return navigate(backRoute);
    }

    navigate(-1);
  };

  return (
    <React.Fragment>
      {backBtn && (
        <div
          className={
            backBtnClassname
              ? backBtnClassname
              : `flex items-center sticky  top-0 justify-between mb-3 w-full bg-white rounded ${
                withSection ? "px-5 py-2" : "px-7 py-5"
              }`
          }
        >
          <button onClick={handleNavigate}>
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </button>
          { withSection &&
            <div>
                 {section}
            </div>
          }
        </div>
      )}
      {summary && (
        <div className="w-full bg-white rounded mb-3 px-7 py-3">
          <h1 className="text-customBlue text-baseFont text-lg">{title}</h1>
          {summary}
        </div>
      )}
      <div
        className={`${ className ? className : `w-full ${containerBackground} rounded p-7 mb-3 ${mt}`}`}
        id="page-layout"
      >
        {children}
      </div>
    </React.Fragment>
  );
}

export default PageLayout;
